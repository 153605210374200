import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../../utils/GlobalFunction";
import { Constant } from "../../../../config/Constant";

export default class ManageOverheadExceptionByLocationStore {
	agGrid = null;
	per_page = LocalGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	total = 0;
	allColumnIds = [];
	statusValues = null;
	rate_list = null

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	// call change status API
	TogglePublishData = (formData) => {
		const api_link = formData.is_active === 1 ? "deactivate/" : "activate/";
		return Axios.patch("business/overhead-exception/" + api_link + formData.id)
			.then(({ data }) => {
				this.getList({ purpose: Constant.OVERHEAD_EXCEPTION_LISTING_PURPOSE.LOCATION });
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// call api to get records
	getList = (payload) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel();
			var sort = this.agGrid.api.getSortModel();
		}
		this.list_data = null;
		return Axios.post(`business/overhead-exception/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.srno = index + 1;
					item.is_active_display =
						item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.rate_list = data.list ? data.list.rates : null
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
			if (this.agGrid) {
				this.agGrid.api.setFilterModel(filter);
				this.agGrid.api.setSortModel(sort);
			}
		});
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};
	// Call edit api
	EditData = (formData) => {
		return Axios.post(`/business/overhead-exception/edit/` + formData.id, formData)
			.then(({ data }) => {
				this.getList({ purpose: Constant.OVERHEAD_EXCEPTION_LISTING_PURPOSE.LOCATION });
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	AddData = (formData) => {
		return Axios.post(`/business/overhead-exception/new`, formData)
			.then(({ data }) => {
				this.getList({ purpose: Constant.OVERHEAD_EXCEPTION_LISTING_PURPOSE.LOCATION });
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
}

decorate(ManageOverheadExceptionByLocationStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	statusValues: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	setEditValues: action,
	setStatusValues: action,
	getList: action,
	onFilterChanged: action,
	rate_list : observable
});
