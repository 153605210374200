import NumberFormat from 'react-number-format'
import {
	subDays
} from 'date-fns';
import CryptoJS from "crypto-js";
import { Constant } from "../config/Constant";
import { ApiConstant } from '../config/ApiConstant';
import moment from 'moment';
import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import {
	Dox, DoxExpress, Bag,
	NonDoxByAir,
	NonDoxBySurface,
	NonDoxExpress,
	Packet
} from "../config/IconsConfig"


export const DateComparator = (filterLocalDateAtMidnight, cellValue) => {
	var dateAsString = cellValue

	var dateParts = dateAsString.split("/")
	var cellDate = new Date(Number(dateParts[2]), Number(dateParts[1]) - 1, Number(dateParts[0]))

	if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
		return 0
	}

	if (cellDate < filterLocalDateAtMidnight) {
		return -1
	}

	if (cellDate > filterLocalDateAtMidnight) {
		return 1
	}
}

export const default_roles = {
	admin: 1,
}

let options = [7, 15, 30, 90, 180, 365];

let newDateRangeArr = [];

options.map(obj => {
	let dateObj = {
		label: `Last ${obj} Days`,
		range: () => ({
			startDate: subDays(new Date(), obj),
			endDate: new Date()
		})
	}
	newDateRangeArr.push(dateObj);
	return null
});

export const dateRangeArr = newDateRangeArr;

export const validFileTypes = [
	"application/pdf",
	"image/jpeg",
	"image/png",
	"text/plain",
	"application/msword",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"application/vnd.ms-powerpoint",
	"application/vnd.openxmlformats-officedocument.presentationml.presentation",
	"application/vnd.ms-excel",
	"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
]

export const CurrencyFormat = (prop) => {
	return (
		<NumberFormat value={prop.value} displayType={prop.displayType || 'text'} thousandSeparator={true} thousandsGroupStyle="lakh" {...prop} />
	)
}

export const convertTextToID = (text_array, main_array, text_key, id_key) => {
	let new_array = []
	if (text_array && text_array.values && text_array.values.length > 0) {
		text_array.values.forEach(x => {
			var temp = main_array.find(y => y[text_key] === x)
			if (x && temp) { new_array.push(temp[id_key]) } else {
				insertAt(new_array, 0, x)
			}
		})
	}
	return new_array
}

export const convertError = (formatedErrors) => {
	formatedErrors.forEach((x) => {
		if (x.name.indexOf(".") !== -1) {
			x.name = x.name.split(".");
			x.name.forEach((e, i) => {
				if (!isNaN(parseInt(e))) {
					x.name[i] = parseInt(e);
				}
			});
		}
	});
	return formatedErrors
}

function insertAt(array, index, ...elementsArray) {
	array.splice(index, 0, ...elementsArray);
}

export const globalStatus = (module_name, param_type, param) => {
	switch (module_name) {
		case 'isActiveStatus':
			return getStatusValue(isActiveStatus, param_type, param);
		case 'isExpiredStatus':
			return getStatusValue(isExpiredStatus, param_type, param);
		case 'isAllowedStatus':
			return getStatusValue(isAllowedStatus, param_type, param);
		case 'ScheduledDeliveryWeek':
			return getStatusValue(ScheduledDeliveryWeek, param_type, param);
		case 'rateType':
			return getStatusValue(rateType, param_type, param);
		case 'isActiveStatusValue':
			return getStatusValue(isActiveStatusValue, param_type, param);
		case 'isExpiredStatusValue':
			return getStatusValue(isExpiredStatusValue, param_type, param);
		case 'isAllowedStatusValue':
			return getStatusValue(isAllowedStatusValue, param_type, param);
		case 'approvalStatus':
			return getStatusValue(approvalStatus, param_type, param);
		case 'isCompletedStatusValue':
			return getStatusValue(isCompletedStatusValue, param_type, param);
		case 'isKYCStatus':
			return getStatusValue(isKYCStatus, param_type, param);
		case 'isTerminateDisplayStatus':
			return getStatusValue(isTerminateDisplayStatus, param_type, param)
		case 'isLockDisplayStatus':
			return getStatusValue(isLockDisplayStatus, param_type, param)
		case 'isTerminateDisplayValue':
			return getStatusValue(isTerminateDisplayValue, param_type, param)
		case 'isLockDisplayValue':
			return getStatusValue(isLockDisplayValue, param_type, param)
		case 'issKYCDisplayValue':
			return getStatusValue(isKYCDisplayValue, param_type, param)
		case 'otptitleVerified':
			return getStatusValue(otptitleVerified, param_type, param);
		case 'quotesStatus':
			return getStatusValue(quotesStatus, param_type, param)
		case 'isQuotesStatusValue':
			return getStatusValue(isQuotesStatusValue, param_type, param)
		case 'isOnlyQuotesStatusValue':
			return getStatusValue(isOnlyQuotesStatusValue, param_type, param)
		case 'isContractValue':
			return getStatusValue(isContractValue, param_type, param)
		case 'isClientTypeValue':
			return getStatusValue(isClientTypeValue, param_type, param)
		case 'archiveStatus':
			return getStatusValue(archiveStatus, param_type, param)
		case 'hoapproval':
			return getStatusValue(hoapproval, param_type, param)
		case 'contractValueStatus':
			return getStatusValue(contractValueStatus, param_type, param)
		case 'isContractLevelValue':
			return getStatusValue(contractValueStatus, param_type, param)
		case 'isAllowStatus':
			return getStatusValue(isAllowStatus, param_type, param)
		case 'isClientType':
			return getStatusValue(isClientType, param_type, param)
		case 'isContractLevel':
			return getStatusValue(isContractLevel, param_type, param)
		case 'cashBookingParamsStatus':
			return getStatusValue(cashBookingParamsStatus, param_type, param)
		case 'entryParamsStatus':
			return getStatusValue(entryParamsStatus, param_type, param)
		case 'platformParamsStatus':
			return getStatusValue(platformParamsStatus, param_type, param)
		case 'entryStatus':
			return getStatusValue(entryStatus, param_type, param)
		case 'throughParamsStatus':
			return getStatusValue(throughParamsStatus, param_type, param)
		case 'bagTypeStatus':
			return getStatusValue(bagTypeStatus, param_type, param)
		case 'outWardStatus':
			return getStatusValue(outWardStatus, param_type, param)
		case 'transStatus':
			return getStatusValue(transStatus, param_type, param)
		case 'actionStatus':
			return getStatusValue(actionStatus, param_type, param)
		case 'isFinilized':
			return getStatusValue(isFinilized, param_type, param)
		case 'selectLevel':
			return getStatusValue(selectLevel, param_type, param)
		case 'rateCardPurpose':
			return getStatusValue(rateCardPurpose, param_type, param)
		case 'transferEntryStatus':
			return getStatusValue(transferEntryStatus, param_type, param)
		case 'transferParams':
			return getStatusValue(transferParams, param_type, param)
		case 'isBillingJobstatus':
			return getStatusValue(isBillingJobstatus, param_type, param)
		case 'isBillingJobStatusLov':
			return getStatusValue(isBillingJobStatusLov, param_type, param)
		case 'isBillingTypeValue':
			return getStatusValue(isBillingTypeValue, param_type, param)
		case 'isIntShipmentType':
			return getStatusValue(isIntShipmentType, param_type, param)
			
		default:
			return null
	}
}

const getStatusValue = (array, param_type, param) => {
	if (param_type === 'key') {
		return array[param]
	} else {
		return parseInt(Object.keys(array).find((x) => array[x] === param ? x : false))
	}
}

export const stringEncryption = (string) => {
	return CryptoJS.AES.encrypt(string, ApiConstant.API_KEY).toString().replace(/\+/g, 'xMl3Jk').replace(/\//g, 'Por21Ld').replace(/=/g, 'Ml32');
}

export const stringDecryption = (string) => {
	string = string.replace(/xMl3Jk/g, '+').replace(/Por21Ld/g, '/').replace(/Ml32/g, '=');
	return CryptoJS.AES.decrypt(string, ApiConstant.API_KEY).toString(CryptoJS.enc.Utf8)
}

export const bagTypeStatus = {
	1: 'Consignment',
	2: 'Bag',
	3: 'Packet'
}

export const actionStatus = {
	1: "InScan",
	2: "From Hub",
	3: "From Location",
}


export const isActiveStatus = {
	1: 'Yes',
	0: 'No',
}

export const isIntShipmentType = {
	1: 'DOC',
	2: 'NON DOC ',
}

export const transStatus = {
	1: "By Road",
	2: "By Train",
	3: "By Air",
	4: "By Waterways"
}

export const isActiveDisplayStatus = {
	1: 'Activate',
	0: 'Deactivate',
}

export const isRestrictedStatus = {
	1: 'Approve',
	0: 'Disapprove',
}
export const isOtpStatus = {
	1: 'Approve',
	0: 'Disapprove',
}
export const isActiveButtonName = {
	1: 'Active',
	0: 'Deactive',
}

export const isUserDisplayStatus = {
	1: 'Approve',
	0: 'Block',
}

export const isUserDeviceButtonName = {
	1: 'Approve',
	0: 'Block',
}

export const otptitleVerified = {
	1: 'Verify',
	0: 'Unverify'
}

export const archiveStatus = {
	1: 'Archive',
	0: 'Restore'
}

export const otpVerified = {
	1: 'Verified',
	0: 'Unverified'
}

export const isApproveRejectStatus = {
	1: "Approve",
	0: "Reject"
}
export const notApplicableValues = {
	id: 'NA',
	name: Constant.UNDEFINED_VALUES
}

export const replaceString = (str, find, replace) => {
	if (str) {
		return str.replace(find, replace);
	}
	return str;
}

export const properCaseString = (str) => {
	if (str) {
		return str.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
	}
	return str;
}

export const properUpperCaseString = (str) => {
	if (str) {
		return str.replace(/(^\w)|(\s+\w)/g, letter => letter.toUpperCase());
	}
	return str;
}

export const lowercaseString = (str) => {
	if (str) {
		return str.toLowerCase();
	}
	return str;
}

export const upperCaseString = (str) => {
	if (str) {
		return str.toUpperCase();
	}
	return str;
}

export const convertInrSeprater = (str) => {
	if (str && typeof str !== "number") {
		return Number(str.replace(/,/g, "")).toLocaleString('en-IN');
	} else if (str === 0) {
		return str;
	} else if (str) {
		return str.toLocaleString('en-IN');
	}
}

export const convertInrSepraterWithFractionDigits = (str) => {
	if (str && typeof str !== "number") {
		return Number(str.replace(/,/g, "")).toLocaleString('en-IN', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	} else if (str === 0) {
		return str;
	} else if (str) {
		return str.toLocaleString('en-IN', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2
		});
	}
}

export const convertToSlug = (str) => {
	if (str) {
		return str.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
	}
	return str;
}

export const generateDeliveryBoyUsername = (name, mobile) => {
	name = name.replace(/\s/g, "");
	name = upperCaseString((name.length > 5) ? name.substring(0, 5) : name);
	mobile = (mobile.length > 5) ? mobile.substring(0, 5) : mobile;
	return `${name}.${mobile}`;
}

export const mobileValidation = (mobile) => {
	if (mobile && typeof mobile === 'string') {
		let wrongNumberArr = ["6666666666", "7777777777", "8888888888", "9999999999", "0000000000"];
		let staringStr = ["0", "1", "2", "3", "4", "5"];
		let stringWith = mobile.substring(0, 1);
		if (mobile.length === 10 && !wrongNumberArr.includes(mobile) && !staringStr.includes(stringWith)) {
			return true;
		}
	}
	return false;
}

export const convertDate = (date, format = Constant.DEFAULT_DISPLAY_DATE_TIME_FORMAT) => {
	if (date && moment(date)) {
		return moment(date).utcOffset(Constant.UTCOFFSET).format(format);
	}
	return Constant.UNDEFINED_VALUES
}

export const convertViewAddress = (data) => {
	data.address = `${data.address1}${data.address2 ? `, ${data.address2}` : ''}${data.address3 ? `, ${data.address3},` : ''}`
	data.address = data.address + ` \n ${data.cities ? `${data.cities.name}` : ''}${data.states ? `, ${data.states.name}` : ''}${data.pincode ? ` - ${data.pincode}` : ''}${data.countries ? `, ${data.countries.name}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address;
}

//for use only without plural name Ex. cite's && state's && pincode's
export const converPluralViewsAddress = (data) => {
	data.address = `${data.address1 ? `${data.address1} ,` : ''}${data.address2 ? `${data.address2} ,` : ''}${data?.address3 ? `${data.address3} ,` : ''}`
	data.address = data.address + ` \n ${data.state ? `${data.state} ,` : ''}${data.city ? `${data.city}` : ''}${data.pincode ? ` - ${data.pincode}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address
}

// for Use Only if pincode OBJ
export const convertViewAddresspincodeobj = (data) => {
	data.address = `${data.address1}${data.address2 ? `, ${data.address2}` : ''}${data.address3 ? `, ${data.address3},` : ''}`
	data.address = data.address + ` \n ${data.city ? `${data.city.name}` : ''}${data.state ? `, ${data.state.name}` : ''}${data.pincode ? ` - ${data.pincode.pincode}` : ''}${data.country ? `, ${data.country.name}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address;
}

//header location profile address view
export const convertViewAddresscenterobj = (data) => {
	data.address = `${data.address1}${data.address2 ? `, ${data.address2}` : ''}${data.address3 ? `, ${data.address3},` : ''}`
	data.address = data.address + `, \n ${data.cities ? `${data.cities.name}` : ''}${data.pincode ? ` - ${data.pincode.pincode}` : ''}${data.states ? `, ${data.states.name}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address;
}
//header location profile address view
export const convertBillingViewAddresscenterobj = (data) => {
	data.address = `${data.billing_address1}${data.billing_address2 ? `, ${data.billing_address2}` : ''}${data.billing_address3 ? `, ${data.billing_address3},` : ''}`
	data.address = data.address + `, \n ${data.billing_cities ? `${data.billing_cities.name}` : ''}${data.billing_states ? `, ${data.billing_states?.name}` : ''}${data.billing_pincodes ? ` - ${data.billing_pincodes?.pincode}` : ''}${data.billing_countries ? `, ${data.billing_countries.name}` : ''}`	
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address;
}

// for Use Only if pincode OBJ
export const convertViewAddresspincodeobjwithcities = (data) => {
	data.address = `${data.address1}${data.address2 ? `, ${data.address2}` : ''}${data.address3 ? `, ${data.address3},` : ''}`
	data.address = data.address + ` \n ${data.cities ? `${data.cities.name}` : ''}${data.states ? `, ${data.states.name}` : ''}${data.pincode ? ` - ${data.pincode.pincode}` : ''}${data.countries ? `, ${data.countries.name}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address;
}
export const convertViewPermanentAddresspincodeobj = (data) => {
	data.p_address = `${data.p_address1}${data.p_address2 ? `, ${data.p_address2}` : ''}${data.p_address3 ? `, ${data.p_address3},` : ''}`
	data.p_address = data.p_address + ` \n ${data.permanent_city ? `${data.permanent_city.name}` : ''}${data.permanent_state ? `, ${data.permanent_state.name}` : ''}${data.permanent_pincode ? ` - ${data.permanent_pincode.pincode}` : ''}${data.permanent_country ? `, ${data.permanent_country.name}` : ''}`
	data.p_address = data.p_address.replace(/(,){2,}/gi, ',')
	data.p_address = data.p_address.replace(/,$/, "");

	return data.p_address;
}

export const convertFranchiseeViewAddress = (data) => {
	data.address = `${data.address1}${data.address2 ? `, ${data.address2}` : ''}${data.address3 ? `, ${data.address3},` : ''}`
	data.address = data.address + ` \n ${data.city_id ? `${data.city_id}` : ''}${data.state ? `, ${data.state.name}` : ''}${data.pincode ? ` - ${data.pincode.pincode}` : ''}${data.countries ? `, ${data.countries.name}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address;
}
export const convertParentFranchiseeViewAddress = (data) => {
	data.p_address = `${data.p_address1}${data.p_address2 ? `, ${data.p_address2}` : ''}${data.p_address3 ? `, ${data.p_address3},` : ''}`
	data.p_address = data.p_address + ` \n ${data.p_city_id ? `${data.p_city_id}` : ''}${data.permanent_state ? `, ${data.permanent_state?.name}` : ''}${data.permanent_pincode ? ` - ${data?.permanent_pincode?.pincode}` : ''}${data.countries ? `, ${data.countries.name}` : ''}`
	data.p_address = data.p_address.replace(/(,){2,}/gi, ',')
	data.p_address = data.p_address.replace(/,$/, "");

	return data.p_address;
}

// for Use Only if city No OBJ
export const convertViewAddressCityNobj = (data) => {
	data.address = `${data.address1}${data.address2 ? `, ${data.address2}` : ''}${data.address3 ? `, ${data.address3},` : ''}`
	data.address = data.address + ` \n ${data.city ? `${data.city}` : ''}${data.states ? `, ${data.states.name}` : ''}${data.pincode ? ` - ${data.pincode}` : ''}${data.countries ? `, ${data.countries.name}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address;
}
export const convertViewGSTAddress = (data) => {
	data.address = `${data.gst_address1}${data.gst_address2 ? `, ${data.gst_address2}` : ''}${data.gst_address3 ? `, ${data.gst_address3},` : ''}`
	data.address = data.address + ` \n ${data.gst_city ? `${data.gst_city}` : ''}${data.gst_pincode ? ` - ${data.gst_pincode}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address;
}

export const convertBookingReceiptAddress = (data) => {
	data.address = `${data.address1 ? `${data.address1}` : ''}${data.address2 ? `, ${data.address2}` : ''}${data.address3 ? `, ${data.address3},` : ''}`
	data.address = data.address + `,${data.city ? `${data.city}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address;
}

export const convertCaseBookingSenderAddress = (data) => {
	data.address = `${data.s_address1 ? `${data.s_address1}` : ''}${(data?.s_address2) ? `, ${data.s_address2},` : ''}${data?.s_address3 ? `, ${data.s_address3},` : ''}`
	data.address = data.address + `${(data.address) ? ` \n` : ''} ${(data?.s_mst_cities?.name) ? `${data?.s_mst_cities?.name}` : ''}${(data?.s_mst_states?.name) ? `, ${data?.s_mst_states?.name}` : ''}${(data?.s_mst_pincode?.pincode) ? ` - ${data?.s_mst_pincode?.pincode}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address
}

export const convertCaseBookingArchiveSenderAddress = (data) => {
	data.address = `${data.s_address1 ? `${data.s_address1}` : ''}${(data?.s_address2) ? `, ${data.s_address2},` : ''}${data?.s_address3 ? `, ${data.s_address3},` : ''}`
	data.address = data.address + `${(data.address) ? ` \n` : ''} ${(data?.s_city_name) ? `${data?.s_city_name}` : ''}${(data?.s_state_name) ? `, ${data?.s_state_name}` : ''}${(data?.s_pincode) ? ` - ${data?.s_pincode}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address
}

export const convertCaseBookingReceiverAddress = (data) => {
	data.receiverAddress = `${data.r_address1 ? `${data.r_address1}` : ''}${data.r_address2 ? `, ${data.r_address2},` : ''}${data?.r_address3 ? `, ${data.r_address3},` : ''}`
	data.receiverAddress = data.receiverAddress + `${(data.receiverAddress) ? ` \n` : ''}  ${(data?.r_city) ? `${data?.r_city}` : ''}${(data?.r_state) ? `, ${data?.r_state}` : ''}${(data?.to_mst_pincode) ? ` - ${data?.to_mst_pincode?.pincode}` : ''}`
	data.receiverAddress = data.receiverAddress.replace(/(,){2,}/gi, ',')
	data.receiverAddress = data.receiverAddress.replace(/,$/, "");

	return data.receiverAddress
}

export const convertCaseBookingArchiveReceiverAddress = (data) => {
	data.receiverAddress = `${data.r_address1 ? `${data.r_address1}` : ''}${data.r_address2 ? `, ${data.r_address2},` : ''}${data?.r_address3 ? `, ${data.r_address3},` : ''}`
	data.receiverAddress = data.receiverAddress + `${(data.receiverAddress) ? ` \n` : ''}  ${(data?.r_city) ? `${data?.r_city}` : ''}${(data?.r_state) ? `, ${data?.r_state}` : ''}${(data?.to_pincode) ? ` - ${data?.to_pincode}` : ''}`
	data.receiverAddress = data.receiverAddress.replace(/(,){2,}/gi, ',')
	data.receiverAddress = data.receiverAddress.replace(/,$/, "");

	return data.receiverAddress
}

export const convertRTOSenderAddress = (data) => {
	data.senderAddress = `${data.s_address1 ? `${data.s_address1}` : ''}${data.s_address2 ? `, ${data.s_address2},` : ''}${data?.s_address3 ? `, ${data.s_address3},` : ''}`
	data.senderAddress = data.senderAddress + `${(data.senderAddress) ? ` \n` : ''}  ${(data?.s_city) ? `${data?.s_city}` : ''}${(data?.s_state) ? `, ${data?.s_state}` : ''}${(data?.s_pincode_remarks) ? ` - ${data?.s_pincode_remarks}` : ''}`
	data.senderAddress = data.senderAddress.replace(/(,){2,}/gi, ',')
	data.senderAddress = data.senderAddress.replace(/,$/, "");

	return data.senderAddress
}

export const convertRTOReceiverAddress = (data) => {
	data.receiverAddress = `${data.r_address1 ? `${data.r_address1}` : ''}${data.r_address2 ? `, ${data.r_address2},` : ''}${data?.r_address3 ? `, ${data.r_address3},` : ''}`
	data.receiverAddress = data.receiverAddress + `${(data.receiverAddress) ? ` \n` : ''}  ${(data?.r_city) ? `${data?.r_city}` : ''}${(data?.r_state) ? `, ${data?.r_state}` : ''}${(data?.r_pincode_remarks) ? ` - ${data?.r_pincode_remarks}` : ''}`
	data.receiverAddress = data.receiverAddress.replace(/(,){2,}/gi, ',')
	data.receiverAddress = data.receiverAddress.replace(/,$/, "");

	return data.receiverAddress
}

export const convertIntBookingSenderAddress = (data) => {
	data.address = `${data.s_address1 ? `${data.s_address1}` : ''}${(data?.s_address2) ? `, ${data.s_address2},` : ''}${data?.s_address3 ? `, ${data.s_address3},` : ''}`
	data.address = data.address + `${(data.address) ? ` \n` : ''} ${(data?.s_city) ? `${data?.s_city}` : ''}${(data?.s_state) ? `, ${data?.s_state}` : ''}${(data?.s_country) ? `, ${data?.s_country}` : ''}${(data?.s_pincode_id) ? ` - ${data?.s_pincode_id}` : ''}`
	data.address = data.address.replace(/(,){2,}/gi, ',')
	data.address = data.address.replace(/,$/, "");

	return data.address
}
export const convertIntBookingReceiverAddress = (data) => {
	data.receiverAddress = `${data.r_address1 ? `${data.r_address1}` : ''}${data.r_address2 ? `, ${data.r_address2},` : ''}${data?.r_address3 ? `, ${data.r_address3},` : ''}`
	data.receiverAddress = data.receiverAddress + `${(data.receiverAddress) ? ` \n` : ''}  ${(data?.r_city) ? `${data?.r_city}` : ''}${(data?.r_state) ? `, ${data?.r_state}` : ''}${(data?.r_country) ? `, ${data?.r_country}` : ''}${(data?.r_zipcode_name) ? ` - ${data?.r_zipcode_name}` : ''}`
	data.receiverAddress = data.receiverAddress.replace(/(,){2,}/gi, ',')
	data.receiverAddress = data.receiverAddress.replace(/,$/, "");

	return data.receiverAddress
}

export const randomnumbersubstring = () => {
	return Math.floor(100000 + Math.random() * 900000);
}

export const generateRandomString = (length) => {
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  	const charLength = characters.length;
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charLength);
    result += characters.charAt(randomIndex);
  }
  return result;
}

export const LEVEL_LIST_HO = [
	{
		id: 101,
		name: "HO",
	},
]

export const LEVEL_LIST_HUB = [
	{
		id: 111,
		name: "HUB",
	},
]

export const LEVEL_LIST_FRANCHISEE = [
	{
		id: 121,
		name: "FRAINCHAISEE",
	},
]

export const LEVEL_LIST_CENTERS = [
	{
		id: 131,
		name: "LOCATION",
	},
]

export const isCAPTCHAVerificationName = {
	1: 'Yes',
	0: 'No',
}

export const rateType = {
	1: "Cash Booking",
	2: "Account Booking"
}

export const stateType = {
	0: 'State',
	1: 'Union Territories'
}

export const levels = {
	0: 'State',
	1: 'Union Territories'
}

export const Userlevels = [101, 111, 121, 131, 141, 151];

export const ScheduledDeliveryWeek = [
	{ id: "0", name: 'Mon' },
	{ id: "1", name: 'Tue' },
	{ id: "2", name: 'Wed' },
	{ id: "3", name: 'Thu' },
	{ id: "4", name: 'Fri' },
	{ id: "5", name: 'Sat' }
]


export const deliveryTagList = [
	{ id: 1, name: 'NON SERVICEABLE', is_active: 1, is_deleted: 0 },
	{ id: 2, name: 'SERVICEABLE', is_active: 1, is_deleted: 0 },
	{ id: 4, name: 'ODA1', is_active: 1, is_deleted: 0 },
	{ id: 5, name: 'ODA2', is_active: 1, is_deleted: 0 }
]

export const getScheduleData = (data, key = "id") => {
	let week = [];
	if (data !== "") {
		let arr2 = data.split(",");
		week = ScheduledDeliveryWeek.filter(item => arr2.includes(item.id)).map((val) => { return val[key] });
	}
	return week;
}

export const isActiveStatusValue = [
	{
		id: 1,
		name: "Yes",
	},
	{
		id: 0,
		name: "No"
	}
]

export const isAllowedStatus =
{
	1: "Allowed",
	0: "Denied"
}

export const isAllowStatus =
{
	1: "Allow",
	0: "Deny"
}


export const isAllowedStatusValue = [
	{
		id: 1,
		name: "Allowed",
	},
	{
		id: 0,
		name: "Denied"
	}
]

export const isExpiredStatus = {
	1: "Active",
	0: "Expired"
}

export const isExpiredStatusValue = [
	{
		id: 1,
		name: "Active",
	},
	{
		id: 0,
		name: "Expired"
	}
]

export const getFirstRecordValue = (data, key, index = 0) => {
	return data[index][key];
}

export const getFirstRecordNameValue = (data, index = 0) => {
	return data[index];
}

export const approvalStatus = {
	10: "Pending Approval",
	20: "Approved",
}

export const approvalStoreStatus = [
	{
		id: 10,
		name: "Pending Approval",
	},
	{
		id: 20,
		name: "Approved"
	},
]


export const isCompletedStatusValue = [
	{
		id: 1,
		name: "Yes",
	},
	{
		id: 0,
		name: "No"
	}
];

export const isCompletedStatus = {
	1: 'Yes',
	0: 'No',
}
export const isForwardingOnlyStatus = {
	1: 'Yes',
	0: 'No',
}
export const isKYCStatus = {
	0: 'Pending',
	1: 'Completed',
}
export const KYCStatusColor = {
	0: 'red',
	1: 'green',
}

export const isTerminateDisplayStatus = {
	1: 'Terminate',
	0: 'Revoke',
}
export const isLockButtonName = {
	1: 'Lock',
	0: 'Unlock',
}

export const isLockDisplayStatus = {
	1: 'Lock ',
	0: 'Unlock'
}
export const isTerminateButtonName = {
	1: 'Terminate',
	0: 'Revoke',
}
export const isInternationaDisplayStatus = {
	1: "Allow",
	0: "Revoke"
}
export const isInternationaDisplayStatusNew = {
	1: "Allow",
	0: "Terminate"
}
export const isInternationalDisplayValue = [
	{
		id: 1,
		name: "Yes",
	},
	{
		id: 0,
		name: "No"
	}
]
export const isTerminateDisplayValue = [
	{
		id: 1,
		name: "Terminate",
	},
	{
		id: 0,
		name: "Revoke"
	}
]
export const isLockDisplayValue = [
	{
		id: 1,
		name: "Lock",
	},
	{
		id: 0,
		name: "Unlock"
	}
]
export const isKYCDisplayValue = [
	{
		id: 1,
		name: "Completed",
	},
	{
		id: 0,
		name: "Pending"
	}
]
export const isLevelDisplayValue = [
	{
		id: 1,
		name: "Hub",
	},
	{
		id: 0,
		name: "Location",
	}
]

export const isAccountDisplayValue = [
	{
		id: 1,
		name: "Hub",
	},
	{
		id: 0,
		name: "Client",
	}
]

export const isRateAccountLevelDisplayValue = [
	{
		id: 1,
		name: "Hub",
	},
	{
		id: 0,
		name: "Client",
	}
]

export const OverheadTypeValues = [
	{
		id: 1,
		name: "Quotation",
	},
	{
		id: 2,
		name: "Franchisee Overhead",
	},
	{
		id: 3,
		name: "Ho Overhead",
	}
]

export const isBillingTypeValue = [
	{
		id: 1,
		name: "Pan India",
	},
	{
		id: 2,
		name: "By Group",
	},
	{
		id: 3,
		name: "By Location"
	}
]

export const themeColorHex = (string) => {
	if (string) {
		let color = string.split(",");
		return "#" + rgbToHex(parseInt(color[0])) + rgbToHex(parseInt(color[1])) + rgbToHex(parseInt(color[2]));
	}
}

export const rgbToHex = (c) => {
	var hex = c.toString(16);
	return hex.length === 1 ? "0" + hex : hex;
}

export const pendingComplateStatus = {
	0: 'Pending',
	1: 'Completed',
}

export const isComputerizedStatus = {
	1: 'Online',
	0: 'Offline',
}

export const quotesStatus = {
	1: 'Quotation',
	2: 'Quotation Approved',
	3: 'HO Rejection',
	4: 'On Board',
}

export const hoapproval = {
	0: "Approve",
	1: "Reject"
}
export const isQuotesStatusValue = [
	{
		id: 1,
		name: "Quotation",
	},
	{
		id: 2,
		name: "Quotation Approved"
	},
	{
		id: 3,
		name: "HO Rejection"
	},
	{
		id: 4,
		name: "On Board"
	}
]

export const isOnlyQuotesStatusValue = [
	{
		id: 1,
		name: "Quotation",
	},
	{
		id: 2,
		name: "Quotation Approved"
	},
	{
		id: 3,
		name: "HO Rejection"
	},
]

export const isHoOnlyQuotesStatusValue = [
	{
		id: 1,
		name: "Quotation Approved"
	},
]

export const isContractValue = [
	{
		id: 1,
		name: "Generated",
	},
	{
		id: 0,
		name: "Pending"
	}
]
export const contractValueStatus = {
	0: 'Pending',
	1: 'Generated',
}

export const isClientTypeValue = [
	{
		id: 1,
		name: "Normal",
	},
	{
		id: 2,
		name: "Corporate"
	},
	{
		id: 3,
		name: "Barter"
	}
]

export const isClientType = {
	1: "Normal",
	2: "Corporate",
	3: "Barter"
}

export const isContractLevelValue = [
	{
		id: 101,
		name: "HO",
	},
	{
		id: 131,
		name: "Location"
	},
]

export const isPartyTypeValue = [
	{
		id: 1,
		name: "Continue",
	},
	{
		id: 2,
		name: "Party Wise"
	},
]

export const isCreatLocation = [
	{
		id: 1,
		name: "New",
	},
	{
		id: 2,
		name: "Existing"
	},
]
export const isContractLevel = {
	101: "HO",
	131: "Location"
}

export const selectLevel = [
	{
		id: 101,
		name: "HO"
	},
	{
		id: 111,
		name: "Hub"
	}
]

export const generatePaymentQRCode = (amount, UPIID) => {
	amount = parseFloat(amount).toFixed(2);
	let URL = `upi://pay?pa=${UPIID}&pn=Shree Nandan Courier&am=${amount}&cu=INR`
	let upiUri = encodeURIComponent(URL);
	return `https://quickchart.io/qr?size=225&text=${upiUri}`
}

export const JsonToFormData = (data, excludedKeys) => {
	const formData = new FormData();
	Object.keys(data).forEach(key => {
		if (!excludedKeys.includes(key)) {
			formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
		}
	})
	return formData
}

export const outWardStatusList = [
	{
		id: 1,
		name: "Outscan Only"
	},
	{
		id: 2,
		name: "Inscan Only"
	},
	{
		id: 3,
		name: "I / O Done"
	}
]

export const outWardStatus = {
	1: "Outscan Only",
	2: "Inscan Only",
	3: "I / O Done"
}

export const stickerListingDropDown = [
	{
		id: 1,
		name: "Pending",
	},
	{
		id: 2,
		name: "History"
	},
	{
		id: 3,
		name: "Outstanding Payments"
	},
	{
		id: 4,
		name: "Pending Refund"
	}
];

export const ROLE_LIST = [
	{
		id: 13,
		name: "Delivery Boy",
	},
];

export const THROUGH_LIST = [
	{
		id: 1,
		name: "Own Vehicle"
	},
	{
		id: 2,
		name: "Co-Loader"
	},
	{
		id: 3,
		name: "Rented Vehicle"
	}
]

export const THROUGH_LIST_LOCATION = [
	{
		id: 1,
		name: "Own Vehicle"
	},
	{
		id: 3,
		name: "Rented Vehicle"
	}
]

export const entryStatus = [
	{
		id: 101,
		name: "Missing Information"
	},
	{
		id: 102,
		name: "Draft"
	},
	{
		id: 105,
		name: "Finalized"
	},
	{
		id: 201,
		name: "Transit",
	},
	{
		id: 205,
		name: "Opened"
	},
	{
		id: 991,
		name: "Archived"
	},
	{
		id: 999,
		name: "Lost"
	}
]

export const entryParamsStatus = {
	101: "Missing Information",
	102: "Draft",
	105: "Finalized",
	201: "Transit",
	205: "Opened",
	991: "Archived",
	999: "Lost",
}

export const bookingStatus = {
	101: "Missing Booking Info",
	105: "Booking Confirmed",
	201: "In Transit",
	301: "Out For Delivery",
	310: "Delivery Failed",
	401: "Delivered",
	501: "Returned",
	502: "Received Back",
	503: "Returned to Client/Customer",
	901: "Cancelled"
}

export const transferEntryStatus = {
	101: "Missing Booking Info",
	105: "Booking Confirmed",
	201: "In Transit",
	301: "Out For Delivery",
	310: "Delivery Failed",
	401: "Delivered",
	901: "Cancelled",
}

export const throughParamsStatus = {
	1: "Own Vehicle",
	2: "Co-Loader",
	3: "Rented Vehicle"
}

export const transferParams = [
	{
		id: Constant.TRANSFER_STATUS.MISSING,
		name: "Missing Booking Info",
	},
	{
		id: Constant.TRANSFER_STATUS.CONFIRMED,
		name: "Booking Confirmed",
	},
	{
		id: Constant.TRANSFER_STATUS.INTRANSIT,
		name: "In Transit ",
	},
	{
		id: Constant.TRANSFER_STATUS.OUTFORDELIVERY,
		name: "Out For Delivery",
	},
	{
		id: Constant.TRANSFER_STATUS.DELIVERYFAILED,
		name: "Delivery Failed",
	},
	{
		id: Constant.TRANSFER_STATUS.DELIVERED,
		name: "Delivered",
	},
	{
		id: Constant.TRANSFER_STATUS.CANCELLED,
		name: "Cancelled",
	}
]

export const cashBookingStatus = [
	{
		id: Constant.BOOKING_STATUS.MISSING,
		name: "Missing Booking Info"
	},
	{
		id: Constant.BOOKING_STATUS.CONFIRMED,
		name: "Booking Confirmed"
	},
	{
		id: Constant.BOOKING_STATUS.INTRANSIT,
		name: "In Transit"
	},
	{
		id: Constant.BOOKING_STATUS.OUTFORDELIVERY,
		name: "Out For Delivery"
	},
	{
		id: Constant.BOOKING_STATUS.DELIVERYFAILED,
		name: "Delivery Failed"
	},
	{
		id: Constant.BOOKING_STATUS.DELIVERED,
		name: "Delivered"
	},
	{
		id: Constant.BOOKING_STATUS.RETURNED,
		name: "Returned"
	},
	{
		id: Constant.BOOKING_STATUS.RECEIVEDBACK,
		name: "Received Back"
	},
	{
		id: Constant.BOOKING_STATUS.RETURNED_CUST,
		name: "Returned to Client/Customer"
	},
	{
		id: Constant.BOOKING_STATUS.CANCLLED,
		name: "Cancelled"
	},
];

export const drsWebDropDown = [
	{
		id: 1,
		name: "Pending DRS",
	},
	// {
	// 	id: 2,
	// 	name: "Scan Pending"
	// },
	{
		id: 3,
		name: "DRS - History"
	},
]

export const infoOption = [
	{ value: 1, name: "Phone" },
	{ value: 2, name: "Mobile" },
]
export const conNoTypeOption = [
    { value: 1, name: "Single" },
    { value: 2, name: "Multiple" },
  ];

export const includePrintOptions = [ 
	{ id :1 , name: "Contact Person"},
	{ id :2 , name: "Email"},
	{ id :3 , name: "Phone"}
]

export const cashBookingParamsStatus = {
	10: "Booking is missing",
	20: "Pending",
	30: "Confirmed",
	40: "In Transit",
	50: "Delivered",
	60: "Returned",
	70: "Cancelled",
};


export const stickerBagListingDropDown = [
	{
		id: 1,
		name: "Pending",
	},
	{
		id: 2,
		name: "History"
	},
]

export const platformStatus = [
	{
		id: 1,
		name: "Web",
	},
	{
		id: 2,
		name: "Mobile"
	},
]
export const platformParamsStatus = {
	1: "Web",
	2: "Mobile",
};

export const DRSStatus = [
	{
		id: 1,
		name: "Pending",
	},
	{
		id: 2,
		name: "Pending Closure"
	},
]
export const DRSParamsStatus = {
	1: "Pending",
	2: "Pending Closure",
};

export const isFinilized = {
	0: "Finalized",
	1: "Draft"
}

export const isFinilizedStatus = [
	{
		id: 0,
		name: "Finalized"
	},
	{
		id: 1,
		name: "Draft"
	}
]

export const REPORT_TYPE_LIST = [
	{
		id: 1,
		name: "Summary",
	},
	{
		id: 2,
		name: "Summary with Additional Info",
	},
	{
		id: 3,
		name: "Detail",
	},
	{
		id: 4,
		name: "Detail with Additional Info",
	},
	{
		id: 5,
		name: "Detail with Overhead Charge",
	},
	{
		id: 6 ,
		name : "Detail with remarks"
	}
]
export const REPORT_TYPE_BOOKING = [
	{
		id: 1,
		name: "Summary",
	},
	{
		id: 2,
		name: "Detail",
	},
	{
		id: 3,
		name: "Basic Summary"
	}
]
export const INVOICE_TYPE = [
	{
		id: 1,
		name: "All",
	},
	{
		id: 2,
		name: "Performa",
	},
	{
		id: 3,
		name: "Tax Invoice"
	}
]
export const REPORT_TYPE_LOCATION = [
	{
		id: 1,
		name: "Summary",
	},
	{
		id: 2,
		name: "Detail",
	},
]

export const REPORT_TYPE_OPTIONS = [
	{
		id: 1,
		name: "Summary",
	},
	{
		id: 2,
		name: "Detail",
	},
]
export const DRS_STATISTICS_REPORT_TYPE_OPTIONS = [
	{
		id: 1,
		name: "Normal",
	},
	{
		id: 2,
		name: "Zero Inscan",
	},
	{
		id: 3,
		name: "Zero DRS",
	},
]
export const INVOICE_REPORT_TYPE_OPTIONS = [
	{
		id: 1,
		name: "Detail",
	},
	{
		id: 2,
		name: "Summary By Hub",
	},
]
export const REPORT_HOTOFO_TYPE_OPTIONS = [
	{
		id: 1,
		name: "Detail",
	},
	{
		id: 2,
		name: "Summary By Hub",
	},
	{
		id: 3,
		name: "Summary By Location"
	}
]
export const REPORT_EXPENSE_TYPE_OPTIONS = [
	{
		id: 1,
		name: "Detail",
	},
	{
		id: 2,
		name: "Summary By Hub",
	},
	{
		id: 3,
		name: "Summary By Head"
	}
]
export const REPORT_EXPENSE_HO_OPTIONS = [
	{
		id: 1,
		name: "Detail",
	},
	{
		id: 3,
		name: "Summary By Head"
	}
]
export const REPORT_HOTOFO_TYPE_OPTIONS_RE = [
	{
		id: 1,
		name: "Detail",
	},
	{
		id: 2,
		name: "Summary By Hub",
	},
	{
		id: 3,
		name: "Summary By Location"
	},
	{
		id: 4,
		name: "Summary By Corporate"
	}
]
export const REPORT_CORPO_TYPE_OPTIONS = [
	{
		id: 1,
		name: "Detail",
	},	
	{
		id: 2,
		name: "Summary By Corporate"
	}
]
export const REPORT_AC_TYPE_OPTIONS = [
	{
		id: 1,
		name: "Detail",
	},	
	{
		id: 2,
		name: "Summary By Account Party"
	}
]
export const REPORT_GST_OP = [
	{
		id: 1,
		name: "All",
	},
	{
		id: 2,
		name: "With GST",
	},
	{
		id: 3,
		name: "Without GST",
	}
]
export const ADDITIONAL_DETAIL_OPTION = [
	{
		id: 1,
		name: "With Additional Charges",
	},
	{
		id: 2,
		name: "With Other Charges",
	},
	{
		id: 3,
		name: "With Additional and Other Charges",
	}
]
export const MOBILE_NUMBER_OPTION = [
	{
		id: 1,
		name: "All",
	},
	{
		id: 2,
		name: "Sender",
	},
	{
		id: 3,
		name: "Receiver",
	}
]
export const MONTH_OPTION = [
	{
		id: "01",
		name: "January",
	},
	{
		id: "02",
		name: "February",
	},
	{
		id: "03",
		name: "March",
	},
	{
		id: "04",
		name: "April",
	},
	{
		id: "05",
		name: "May",
	},
	{
		id: "06",
		name: "June",
	},
	{
		id: "07",
		name: "July",
	},
	{
		id: "08",
		name: "August",
	},
	{
		id: "09",
		name: "September",
	},
	{
		id: "10",
		name: "October",
	},
	{
		id: "11",
		name: "November",
	},
	{
		id: "12",
		name: "December",
	},
]
export const REPORT_PAYMENT_TYPES_OP = [
	{
		id: 1,
		name: "All",
	},
	{
		id: 2,
		name: "Bill",
	},
	{
		id: 3,
		name: "Estimation",
	}
]
export const REPORT_TYPE_OUTSCAN_INSCAN_ACTIVITIES = [
	{
		id: 1,
		name: "By Hub/Location",
	},
	{
		id: 2,
		name: "By Date",
	},
]
export const HO_GST_TYPE = [
	{
		id: 1,
		name: "All",
	},
	{
		id: 2,
		name: "Corporates",
	},
	{
		id: 3,
		name: "Sticker"
	},
	{
		id : 4,
		name:"International"
	}
]
export const REPORT_OP_TYPE = [
	{
		id: 1,
		name: "All",
	},
	{
		id: 2,
		name: "Yes",
	},
	{
		id: 3,
		name: "No"
	}
]
export const REPORT_SW_CHRG_TYPE = [
	{
		id: 1,
		name: "All",
	},
	{
		id: 2,
		name: "Yes",
	},
	{
		id: 3,
		name: "No"
	}
]
export const INTER_SHIPMENT_TYPE_OP = [
	{
		id: 1,
		name: "Doc",
	},
	{
		id: 2,
		name: "Non Doc",
	},
]
export const rateCardPurpose = {
	1: Constant.RATECARD_PURPOSE[1],
	2: Constant.RATECARD_PURPOSE[2],
	3: Constant.RATECARD_PURPOSE[3],
	4: Constant.RATECARD_PURPOSE[4],
	5: Constant.RATECARD_PURPOSE[5],
	6: Constant.RATECARD_PURPOSE[6],
}

export const DRSDocumentsPerPage = [
	{
		id: 12,
		name: "12"
	},
	{
		id: 15,
		name: "15"
	}
]

export const PRINT_STYLE_LIST = [
	{
		id: 1,
		name: "With Reference Number",
	},
	{
		id: 2,
		name: "With Valuation Charge",
	},
	{
		id: 3,
		name: "Single Page",
	},
]
export const CLIENT_PRINT_STYLE_LIST = [
	{
		id: 1,
		name: "With Reference Number",
	},
	{
		id: 2,
		name: "With Valuation Charge",
	},
	{
		id: 3,
		name: "Single Page",
	},
	{
		id: 4,
		name: "Without Child Booking",
	},
]

export const PRINT_TYPE_LIST = [
	{
		id: 1,
		name: "Original",
	},
	{
		id: 2,
		name: "Duplicate",
	},
	{
		id: 3,
		name: "Triplicate",
	},
]

export const CORPORATE_PRINT_STYLE_LIST = [
	{
		id: 1,
		name: "With Detail",
	},
	{
		id: 3,
		name: "Single Page",
	},
	{
		id: 4,
		name: "Invoice With Detail",
	},
	{
		id: 5,
		name: "Without Child Booking",
	},
]
export const DISCOUNTID_OP_TYPE = [
	{
		id: 1,
		name: "In Fixed",
	},
	{
		id: 2,
		name: "In Percentage",
	}
]

export const ExpenseCreditDebitOption = [
	{
		id: 0,
		name: "Debit"
	},
	{
		id: 1,
		name: "Credit"
	}
]
export const consignmentTagStatus = (status) => {
	switch (status) {
		case Constant.BOOKING_STATUS.MISSING:
			return (<Tag icon={<SearchOutlined />} color="red" >Missing Booking Info</Tag>);
		case Constant.BOOKING_STATUS.CONFIRMED:
			return (<Tag icon={<CheckCircleOutlined />} color="cyan" >Booking Confirmed</Tag>);
		case Constant.BOOKING_STATUS.DELIVERED:
			return (<Tag icon={<CheckCircleOutlined />} color="success" >Delivered</Tag>);
		case Constant.BOOKING_STATUS.DELIVERYFAILED:
			return (<Tag icon={<CloseCircleOutlined />} color="red" >Delivery Failed</Tag>);
		case Constant.BOOKING_STATUS.INTRANSIT:
			return (<Tag icon={<CheckCircleOutlined />} color="orange" >In Transit</Tag>);
		case Constant.BOOKING_STATUS.OUTFORDELIVERY:
			return (<Tag icon={<CheckCircleOutlined />} color="geekblue" >Out For Delivery</Tag>);
		case Constant.BOOKING_STATUS.RECEIVEDBACK:
			return (<Tag icon={<CheckCircleOutlined />} color="warning" >Received Back</Tag>);
		case Constant.BOOKING_STATUS.RETURNED:
			return (<Tag icon={<CheckCircleOutlined />} color="volcano" >Returned</Tag>);
		case Constant.BOOKING_STATUS.CANCLLED:
			return (<Tag icon={<CloseCircleOutlined />} color="volcano" >Cancelled</Tag>);
		default:
			return "";
	}
}
export const widgetImage = (data) => {
	switch (true) {
		case data.product_id === Constant.SERVICE_NAME.DOX:
			return (
				<Dox />
			);
		case data.product_id === Constant.SERVICE_NAME.DOXEXPRESS:
			return (
				<DoxExpress />
			);
		case data.product_id === Constant.SERVICE_NAME.NONDOXBYAIR:
			return (
				<NonDoxByAir />
			);
		case data.product_id === Constant.SERVICE_NAME.NONDOXBYSURFACE:
			return (
				<NonDoxBySurface />
			);
		case data.product_id === Constant.SERVICE_NAME.NONDOXEXPRESS:
			return (
				<NonDoxExpress />
			);
		case data.ti_id === Constant.TYPE_PCK:
			return (
				<Packet />
			)
		case data.ti_id === Constant.TYPE_BAG:
			return (
				<Bag />
			)
		default:
			return "";
	}
}
export const b64toBlob = (b64Data, contentType, sliceSize) => {
	contentType = contentType || "";
	sliceSize = sliceSize || 512;

	var byteCharacters = Buffer.from(b64Data, 'base64').toString('binary');
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	//console.log(byteArrays);

	return new File(byteArrays, "pot", { type: contentType });
};

export const addLeadingZeros = (num, totalLength) => {
	return String(num).padStart(totalLength, '0');
};

/**
 * Generate PDF view for cashbooking print
 */
export const cashBookingPdfPrint = (htmlString, data) => {
	let weight, gross;

	data.c_weight = Number(data.c_weight);
	if (data.c_weight >= 1000) {
		weight = `${(data.c_weight / 1000).toFixed(2)} <sup>kgs</sup>`;
	} else {
		weight = `${(data.c_weight).toFixed(2)} <sup>gms</sup>`
	}
	gross = (Number(data.gross)).toFixed(2);

	data.bk_date = convertDate(data.bk_date, Constant.DEFAULT_DATE_FORMAT);

	htmlString = replaceString(htmlString, "#LOGO#", data.logo);
	htmlString = replaceString(htmlString, "#ADDRESS#", data.address);
	htmlString = replaceString(htmlString, "#ORIGIN#", data.center.name);
	htmlString = replaceString(htmlString, "#PRODUCTTYPE#", data.product_code);
	htmlString = replaceString(htmlString, "#CENTER_MOBILE#", data.center.mobile);
	htmlString = replaceString(htmlString, "#DESTINATION#", data.r_city);
	htmlString = replaceString(htmlString, "#BOOKING#", "CASH");
	htmlString = replaceString(htmlString, "#CNUMBER_BARCODE#", data.barcode);
	htmlString = replaceString(htmlString, "#DATE#", data.bk_date);
	htmlString = replaceString(htmlString, "#DATETTIME#", data.bk_date);
	htmlString = replaceString(htmlString, "#TYPE#", "CASH");
	htmlString = replaceString(htmlString, "#SENDER_NAME#", data.s_name);
	htmlString = replaceString(htmlString, "#SENDER_ADDRESS#", (data.s_address1) ?? "");
	htmlString = replaceString(htmlString, "#SENDER_CITY#", data.s_city_name);
	htmlString = replaceString(htmlString, "#SENDER_MOBILE#", data.s_mobile);
	htmlString = replaceString(htmlString, "#SENDER_GST#", (data.s_gst_no) ?? "");
	htmlString = replaceString(htmlString, "#RECEIVER_NAME#", data.r_name);
	htmlString = replaceString(htmlString, "#RECEIVER_ADDRESS#", (data.r_address1) ?? "");
	htmlString = replaceString(htmlString, "#RECEIVER_PINCODE#", data.r_pincode_display);
	htmlString = replaceString(htmlString, "#RECEIVER_MOBILE#", (data.r_mobile) ?? "");
	htmlString = replaceString(htmlString, "#DECLARE_VALUE#", data.value_rs.toFixed(2));
	htmlString = replaceString(htmlString, "#INSURED#", (data.is_insured === 1) ? "YES" : "NO");
	//htmlString = replaceString(htmlString, "#POD#", (data.need_pod === 1) ? "YES" : "NO");
	htmlString = replaceString(htmlString, "#POD#", "YES");
	htmlString = replaceString(htmlString, "#WEIGHT#", weight);
	htmlString = replaceString(htmlString, "#GROSS_AMOUNT#", gross);
	htmlString = replaceString(htmlString, "#CGSTLABLE#", (data.igst_chrg > 0) ? "IGST" : "CGST");
	htmlString = replaceString(htmlString, "#SGSTLABLE#", (data.igst_chrg > 0) ? "" : "SGST");
	htmlString = replaceString(htmlString, "#CGST#", (data.igst_chrg > 0 ? Number(data.igst_chrg) : ((data.cgst_chrg) ? Number(data.cgst_chrg) : 0)).toFixed(2));
	htmlString = replaceString(htmlString, "#SGST#", (data.igst_chrg > 0 ? "" : ((data.sgst_chrg) ? Number(data.sgst_chrg) : 0).toFixed(2)));
	htmlString = replaceString(htmlString, "#TOTAL_CHARGE#", data.net.toFixed(2));
	htmlString = replaceString(htmlString, "#REMARKS#", data.remarks);
	htmlString = replaceString(htmlString, "#COMPANY_NAME#", data.center?.company);
	htmlString = replaceString(htmlString, "#LOCAION_GST#", data.center.gst_no ?? "");
	htmlString = replaceString(htmlString, "#DATETTIMENOW#", data.created ?? "");
	htmlString = replaceString(htmlString, "#POLICY_NO#", data?.policy_no ?? "");
	return htmlString;
}

/**
 * Sticker validation for old and new serious of cash and account
 */
export const checkStickerSeries = (sticker) => {
	/**
	 * Check for old stikcer in if condition
	 * 		Need to check 567 charecter less then 221
	 * else New condition
	 * 
	 * Return 
	 * 	TRUE - sticker is not valid 
	 * 	False - Sticker is valid
	 */
	let subSticker = sticker.substr(4, 3);
	if (subSticker < "221") {
		return !([Constant.CASH.toString(), Constant.ACCOUNT.toString(), Constant.INTERNATIONAL.toString(), Constant.ACCOUNTVIRTUALSTIKERS.toString(), Constant.CROSSING.toString()].includes(sticker.toString().split('')[4]));
	} else {
		return !([Constant.CASH.toString(), Constant.ACCOUNT.toString(), Constant.INTERNATIONAL.toString(), Constant.ACCOUNTVIRTUALSTIKERS.toString(), Constant.CROSSING.toString()].includes(sticker.toString().split('')[6]));
	}
}

/**
 * Sticker validation for old and new serious of all.
 * Default cash and account
 * Bag, packet, Express
 */
export const checkProductBasedStickerSeries = (sticker, StickerType = "") => {
	/* Return 
	* 	False - sticker is not valid 
	* 	True - Sticker is valid
	*/
	if (sticker && sticker.length === 13) {
		let subSticker = sticker.substr(4, 3);
		if (subSticker < "221") {
			if (StickerType === Constant.CASH) {
				return !!([Constant.CASH.toString()].includes(sticker.toString().split('')[4]));
			} else if (StickerType === Constant.ACCOUNT) {
				return !!([Constant.ACCOUNT.toString()].includes(sticker.toString().split('')[4]));
			} else {
				return !!([Constant.CASH.toString(), Constant.ACCOUNT.toString()].includes(sticker.toString().split('')[4]));
			}
		} else if (StickerType === Constant.CROSSING) {
			return !!([Constant.CROSSING.toString()].includes(sticker.toString().split('')[6]));
		} else if (StickerType === Constant.CASH) {
			return !!([Constant.CASH.toString()].includes(sticker.toString().split('')[6]));
		} else if (StickerType === Constant.ACCOUNT) {
			return !!([Constant.ACCOUNT.toString(), Constant.ACCOUNTVIRTUALSTIKERS.toString()].includes(sticker.toString().split('')[6]));
		} else {
			return !!([Constant.CASH.toString(), Constant.ACCOUNT.toString()].includes(sticker.toString().split('')[6]));
		}
	} else if (sticker && sticker.length === 11) {
		if (StickerType === Constant.EXPRESS) {
			return !!([Constant.EXPRESS.toString()].includes(sticker.toString().split('')[2]));
		} else if (StickerType === Constant.PACKET) {
			return !!([Constant.PACKET.toString()].includes(sticker.toString().split('')[2]));
		} else if (StickerType === Constant.BAG) {
			return !!([Constant.BAG.toString()].includes(sticker.toString().split('')[2]));
		} else if (StickerType === Constant.BAG_TAG) {
			return !!([Constant.BAG_TAG.toString()].includes(sticker.toString().split('')[2]));
		}
		return !!false;
	}
	return !!false;
}

/**
 * weight conver
 */
export const weightFormatter = (weight) => {
	var final = 0;
	if (weight > 1000) {
		final = weight / 1000
	} else if (weight <= 1000) {
		final = weight
	}
	return typeof final !== 'string' ? ((weight > 1000) ? `${final} kgs` : `${final} gms`) : (typeof final === "string" && final) ? ((weight > 1000) ? `${final} kgs` : `${final} gms`) : Constant.UNDEFINED_VALUES
}

/**
 * weight conver  weight module 
 */
export const weightModuleFormatter = (weight) => {
	var final = 0;
	if (weight > 0) {
		final = weight / 1000
	}
	return typeof final !== 'string' ? `${final}` : Constant.UNDEFINED_VALUES
}

/**
 * Range generation for Sticker
 */
export const rangeGeneration = (from, to, step) => [...Array(Math.floor((to - from) / step) + 1)].map((_, i) => from + i * step);

/**
 * Get sticker product name
 */
export const getStickerProductName = (prod_id) => {
	let productName = "";
	switch (prod_id) {
		case Constant.STICKERS_FOR_CASH_BOOKING:
			productName = "Cash";
			break;
		case Constant.STICKERS_FOR_CASH_BOOKING_B_W:
			productName = "Cash";
			break;
		case Constant.STICKERS_FOR_ACCOUNT_BOOKING_B_W:
			productName = "A/C";
			break;
		case Constant.STICKERS_FOR_ACCOUNT_BOOKING:
			productName = "A/C";
			break;
		case Constant.STICKERS_FOR_EXPRESS_BOOKING:
			productName = "Express";
			break;
		case Constant.COVERS_FOR_EXPRESS_BOOKING:
			productName = "Cover";
			break;
		case Constant.STICKERS_FOR_BAG:
			productName = "Tag";
			break;
		case Constant.STICKERS_FOR_PACKETS:
			productName = "PCK";
			break;

		default:
			break;
	}
	return productName;
}

export const FORWARDBOOKINGACTION = [
	{
		id: Constant.BOOKING_ONLY,
		name: "Booking Only"
	},
	{
		id: Constant.INSCAN,
		name: "Inscan"
	},
	{
		id: Constant.INSCAN_FROM_LOCATION,
		name: "Inscan from Location",
	},
	{
		id: Constant.INSCAN_FROM_HUB,
		name: "Inscan from Hub"
	},
	{
		id: Constant.OUTSCAN_TO_LOCATION,
		name: "Outscan to Location"
	},
	{
		id: Constant.OUTSCAN_TO_HUB,
		name: "Outscan to Hub"
	},
]
export const LabelForwardBooking = {
	10: "Booking Only",
	20: "Inscan From Location",
	30: "Inscan From Hub",
	40:  "Outscan To Location",
	50: "Outscan To Hub",
}
export const checkAccoutBookingStickerSeries = (sticker) => {
	/**
	 * Check for old stikcer in if condition
	 * 		Need to check 567 charecter less then 221
	 * else New condition
	 * 
	 * Return 
	 * 	TRUE - sticker is not valid 
	 * 	False - Sticker is valid
	 */
	if (sticker && sticker.length === 13) {
		let subSticker = sticker.substr(4, 3);
		if (subSticker < "221") {
			return ([Constant.ACCOUNT.toString()].includes(sticker.toString().split('')[4]));
		} else {
			return ([Constant.ACCOUNT.toString()].includes(sticker.toString().split('')[6]));
		}
	} else {
		return true
	}
}

/**
 * This function is made to set logic of disabled effective date in draft for all
 */
export const draftDisableDates = (current) => {
	// Can select days before today and current month
	// return !(current >= moment().startOf('month') && current < moment().endOf('day'));

	// Temporay New logic set due to 1st jun 2023 need to set
	return !(current >= moment('2023/06/01').startOf('day') && current < moment().endOf('day'));
}

export const isBillingJobStatusLov = [
	{
		id: 10,
		name: "Work In Progress",
	},
	{
		id: 20,
		name: "Basic Info"
	},
	{
		id: 30,
		name: "Business information"
	},
	{
		id: 40,
		name: "Business Info Confirmed"
	},
	{
		id: 50,
		name: "Pause"
	},
	{
		id: 60,
		name: "Pending Confirmation"
	},
	{
		id: 70,
		name: "Pending Finalization"
	},
	{
		id: 100,
		name: "Completed",
	},
	{
		id: 101,
		name: "Cancelled"
	},
	{
		id: 102,
		name: "Terminated"
	},

]

export const isBillingJobstatus = {
	10: 'Work In Progress',
	20: 'Basic Info',
	30: 'Business information',
	40: 'Business Info Confirmed',
	50: 'Pause',
	60: 'Pending Confirmation',
	70: 'Pending Finalization',
	100: 'Completed',
	101: 'Cancelled',
	102: 'Terminated',
}

export const isBillingJobstatusColor = {
	10: 'black',
	20: 'black',
	30: 'black',
	40: 'black',
	50: 'yellow',
	60: 'green',
	100: 'green',
	101: 'red',
	102: 'gray',
}

export const internationalPaymentStatus = {
	1: "Payment Entry Done",
	2: "Payment Approved",
	3: "Payment Rejected",
	4: "Re- payment",
	5: "Refunding",
}

// This function will be return disabled startDate and endDate for DRS
export const drsDisableDates = () => {
	return {
		startDate: moment().subtract(5, 'days'),
		endDate: moment().add(2, 'days')
	}
}

// Remove extra spaces, newlines, tabs set only a single space between words
// Ex. Input: My		name	is		abc / Output: My name is abc; 
export const singleLineMaker = (data) => {
	if (data) {
		return data.replace(/\s+/g, ' ').trim()
	} else {
		return null
	}
}

// This function will be return disabled startDate and endDate for Eway Outscan Pending
export const ewayDisableDates = () => {
	return {
		startDate: moment().subtract(7, 'days'),
		endDate: moment().add(0, 'days')
	}
}

export const accountPartyBillGenerateLabelSize = [
	{
		id :1,
		name : "12 Labels per Page (6 x 2)"
    },
	{
		id :2,
		name : "18 Labels per Page (6 x 3)"
    },
]

export const accountPartyBillGenerateLov = [
	{
		id :1,
		name : "All"
    },
	{
		id :2,
		name : "Bill Generated of A/C Party"
    },
]

export const convertRateCardPurposeLov = [
	{
		id: 2,
		name : "Standard Rate Card"
    },
	{
		id: 4,
		name : "Franchisee Overhead"
    },
]

export const labelConvertRateCard = {
	2: "Standard Rate Card",
	4: "Franchisee Overhead"
}

export const INT_UNIT_OF_MEASUREMENT = [
	{
		id: 1,
		name: "Pkt",
	},
	{
		id: 12,
		name: "Pc",
	},
	{
		id: 14,
		name: "PCS",
	},
	{
		id: 2,
		name: "Nos",
	},
	{
		id: 3,
		name: "Bottle",
	},
	{
		id: 4,
		name: "Pair",
	},
	{
		id: 4,
		name: "Strip",
	},
	{
		id: 6,
		name: "Dozen",
	},
	{
		id: 7,
		name: "Gross",
	},
	{
		id: 8,
		name: "Sets",
	},
	{
		id: 9,
		name: "Box",
	},
	{
		id: 10,
		name: "KG",
	},
	{
		id: 11,
		name: "Gram",
	},
	{
		id: 13,
		name: "Container",
	},
	{
		id: 15,
		name: "Carats",
	},
]
export const  dateComparatorSort = (date1, date2) => {
	var date1Number = date1 && date1 !== "N/A" ? parseInt(moment(date1, "DD/MM/YYYY").format("MMDD")) : null;
	var date2Number = date2 && date2 !== "N/A" ? parseInt(moment(date2, "DD/MM/YYYY").format("MMDD")) : null;
	
	if ((date1Number == null && date2Number == null)) {
	  return 0;
	}
	if ( date1Number == null) {
	  return -1;
	} else if (date2Number == null) {
	  return 1;
	}
	return date1Number - date2Number;
  }