import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import { Constant } from "../../../../config/Constant";
import ServerGridConfig from "../../../../config/ServerGridConfig";

export default class AgencyPaymentStore {
	filter = null;
	agGridList = null;
	per_page_list = ServerGridConfig.options.paginationPageSize;
	current_page_list = 1;
	list_data = null;
	total_list = 0;
	allColumnIdsList = [];
	selectAll = false;
	selectCount = 0;
	dropdown_agency_list = null;
	totalVendorSum = 0;
	paymentDetails = null;
	pinnedBottomTotalList = [];
	selectedData = []
	viewValues = null
	dropdown_agency_bank_list = null
	visibleRowCount = 0;
	selectedRowCount = 0;

	setCheckState = (data) => {
		this.selectAll = data;
	};
	setTotalVendorSum = (data) => {
		this.totalVendorSum = data;
	};

	setPaymentDetails = (data) => {
		this.paymentDetails = data;
		return true;
	};
	setSelectedData =(data) => {
		this.selectedData = data
	}

	setViewValues = (data) => {
		this.viewValues = data;
	   };

	setVisibleRowCount = (data) => {
		this.visibleRowCount = data;
	}

	setSelectedRowCount = (data) => {
		this.selectedRowCount = data;
	}
	

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGridList) {
			this.agGridList.api.deselectAll()
			this.agGridList.api.paginationSetPageSize(parseInt(page));
		}
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_sort = params.sortModel;
		return {  final_sort };
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					...this.generatePayload(this.filter),
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGridList.api.showNoRowsOverlay();
					} else {
						this.agGridList.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGridList && this.agGridList.columnApi && data.total) {
						this.agGridList.columnApi
							.getAllColumns()
							.forEach(function (column) {
								allColumnIds.push(column.col_id);
							});
					}
				});
			},
		};
	};

	// set form values to  customefilter
	setListFilterValues = (data = null) => {
		return new Promise((resolve) => {
			this.filter = data;
			if (this.agGridList) {
				this.setupGridList(this.agGridList);
			}
			resolve();
		});
	};

	// Setup grid and set column size to autosize
	setupGridList = (params) => {
		this.pinnedBottomTotalList = []
		this.agGridList = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeList = (page = this.per_page_list) => {
		this.per_page_list = page;
		if (this.agGridList) {
			this.agGridList.api.paginationSetPageSize(parseInt(page));
		}
	};

	// Filter function for no record found message
	onFilterChangedList = (params) => {
		if (this.agGridList) {
			this.agGridList.api.deselectAll();
			this.setupGridList(this.agGridList)
			this.setVisibleRowCount(0)
			this.setSelectedRowCount(0)
			this.setSelectedData([])
		}
		this.agGridList = params;
		if (this.agGridList && this.agGridList.api.getModel().getRowCount() === 0) {
			this.agGridList.api.showNoRowsOverlay();
		}
		if (this.agGridList && this.agGridList.api.getModel().getRowCount() > 0) {
			this.agGridList.api.hideOverlay();
		}
	};

	generatePayload = (filter) => {
		if (filter?.from_date) {
			filter.from_date = moment(filter.from_date).format(Constant.PAYLOAD_DATE);
		}
		if (filter?.to_date) {
			filter.to_date = moment(filter.to_date).format(Constant.PAYLOAD_DATE);
		}
		return filter;
	};
	
	refreshList = () => {
		if (this.agGridList) {
			this.agGridList.api.deselectAll();
			this.setupGridList(this.agGridList);
			this.setCheckState(false)
			this.setSelectedRowCount(0)
			this.setVisibleRowCount(0);
			this.setSelectedData([])
		}
	};
	// call api to get records
	getList = (payload) => {
		return Axios.post(`/international/agy-payment-summary`, payload).then(
			({ data }) => {
				if (data.list?.data?.length) {
					let startPage = (data.list.current_page - 1) * data.list.per_page;
					data.list.data.map((item, index) => {
						item.srno = startPage + index + 1;
						this.setVisibleRowCount(this.visibleRowCount + 1)
						return null;
					});
				}
				if (this.visibleRowCount > 0 && this.visibleRowCount !== this.selectedRowCount) {
					this.setCheckState(false)
				}
				this.pinnedBottomTotalList = (data?.list?.data?.length > 0) ? [data?.footer_total] : [];
				this.list_data = data.list ? data.list.data : null;
				this.total_list = data.list.total;
				this.current_page_list = data.list.current_page;
				return data;
			}
		);
	};

	// Call add api
	AddPaymentData = (formdata) => {
		return Axios.post(`/international/int-agency-payment-save`, formdata)
			.then(({ data }) => {
				if (this.agGridList) {
					this.agGridList.api.deselectAll();
					this.setupGridList(this.agGridList);
					this.setCheckState(false)
					this.setSelectedRowCount(0)
					this.setVisibleRowCount(0);
					this.setSelectedData([])
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	onFirstDataRendered = () => {
		if (this.selectAll === true && this.agGridList) {
			this.agGridList.api.forEachNode((node) => {
				this.selectCount++;
				this.setSelectedRowCount(this.selectedRowCount + 1)
				node.setSelected(true);
			});
		}
	};

	onSelectAllRender = (selectAllFlag) => {
		if (selectAllFlag === true && this.agGridList) {
			this.onFirstDataRendered();
		} else if (this.agGridList) {
			this.agGridList.api.forEachNode((node) => {
				this.setSelectedRowCount(0);
				node.setSelected(false);
			});
		}
	};

	//Get List of agency for dropdown
	getAgency = (conditional = {}) => {
		return Axios.post(`admin/int-agency/lov `, conditional)
			.then(({ data }) => {
				this.dropdown_agency_list = data.lov_agency;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

     //Get List of agency bank for dropdown
	getAgencyBanksList = (conditional = {}) => {
		return Axios.post(`admin/banks/int-agy/lov`, conditional)
		  .then(({ data }) => {
			this.dropdown_agency_bank_list = data.agency_banks;
			return data;
		  })
		  .catch((response) => {
			return Promise.reject(response);
		  });
	  };

	  	// call api to get records
		  ViewData = (ID) => {
			return Axios.get(`international/agy-payment-summary-view/${ID}`).then(({ data }) => {
				this.setViewValues(data.view);
				return data;
			});
		};
}

decorate(AgencyPaymentStore, {
	per_page_list: observable,
	agGridList: observable,
	list_data: observable,
	total_list: observable,
	allColumnIdsList: observable,
	setupGridList: action,
	setPageSizeList: action,
	getList: action,
	setListFilterValues: action,
	onFilterChangedList: action,
	filter: observable,
	selectAll: observable,
	selectCount: observable,
	onFirstDataRendered: action,
	onSelectAllRender: action,
	setCheckState: action,
    dropdown_agency_list: observable,
	getAgency: action,	totalVendorSum: observable,
	setTotalVendorSum: action,
	setPaymentDetails: action,
	paymentDetails: observable,
	pinnedBottomTotalList: observable,
	selectedData :observable,
	setSelectedData :action,
	dropdown_agency_bank_list : observable,
	getAgencyBanksList : action,
	viewValues : observable,
	setViewValues : action,
	visibleRowCount: observable,
	setVisibleRowCount: action,
	selectedRowCount: observable,
	setSelectedRowCount: action,
});
